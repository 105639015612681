import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Character } from "../utils/type.ts";
import { fetchCharacterAPI } from "../utils/api.ts";
import LoadingPage from "../LoadingPage.tsx";
import NotFoundPage from "../NotFoundPage.tsx";
import Cookies from "js-cookie";
import NavbarContainer from "../NavbarContainer.tsx";
import toast from "react-hot-toast";

export default function CharacterPage() {
    const { charID } = useParams();
    const navigate = useNavigate();
    const [character, setCharacter] = useState<Character | null>(null);

    const [category, setCategory] = useState("");
    const [remark, setRemark] = useState("");
    const [image, setImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false); // Add a state for submission status

    const categories = [
        "使用侵权图片（图片举证）",
        "立绘和简介中出现露骨的色情暴力内容",
        "涉及敏感的政治题材",
        "乙女游戏ip角色",
        "其他",
    ];

    const fetchCharacterAsync = async () => {
        if (charID) {
            const character = await fetchCharacterAPI(charID);
            setCharacter(character);
        }
    };

    useEffect(() => {
        fetchCharacterAsync();
    }, []);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true); // Set isSubmitting to true before submission

        if (!category) {
            alert("请选择举报类别");
            setIsSubmitting(false); // Reset isSubmitting to false if category is empty
            return;
        }

        const formData = new FormData();
        formData.append("category", category);
        formData.append("remark", remark);
        if (image) {
            formData.append("file", image);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/characters/${character!.id}/report`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: formData,
            });

            if (response.ok) {
                toast.success("举报成功！");
                navigate(`/character/${character!.id}`);
            } else {
                const errorData = await response.json();
                toast.error("提交举报时发生错误，请稍后重试。");
            }
        } catch (error) {
            console.error("Error submitting report:", error);
            toast.error("提交举报时发生错误，请稍后重试。");
        } finally {
            setIsSubmitting(false); // Reset isSubmitting to false after submission
        }
    };

    if (character === null) {
        return <LoadingPage />;
    }

    if (!character.id) {
        return <NotFoundPage />;
    }

    return (
        <NavbarContainer title="">
            <div className="h-full min-h-screen overscroll-none p-4">
                <h1 className="text-2xl font-bold mb-4">举报角色: {character.name}</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium mb-2">请选择举报类别:</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            className="select select-bordered w-full max-w-xs"
                            required
                        >
                            <option value="" disabled>
                                请选择类别
                            </option>
                            {categories.map((cat, index) => (
                                <option key={index} value={cat}>
                                    {cat}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-2">备注:</label>
                        <textarea
                            value={remark}
                            onChange={(e) => setRemark(e.target.value)}
                            className="textarea textarea-bordered w-full"
                            placeholder="请填写备注信息"
                        ></textarea>
                    </div>
                    <div>
                        <label className="block text-sm font-medium mb-2">上传图片:</label>
                        {imagePreview && (
                            <div className="relative w-40 h-40 mb-4">
                                <img
                                    src={imagePreview}
                                    alt="preview"
                                    className="w-full h-full object-cover rounded-lg"
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        setImage(null);
                                        setImagePreview("");
                                    }}
                                    className="absolute top-0 right-0 m-2 bg-red-500 text-white p-1 rounded-full"
                                >
                                    &times;
                                </button>
                            </div>
                        )}
                        <label htmlFor="image-upload" className="btn btn-secondary">
                            {image ? "更换图片" : "添加图片(1)"}
                        </label>
                        <input
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            hidden
                        />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                        {isSubmitting ? "提交中..." : "提交举报"}
                    </button>
                </form>
            </div>
        </NavbarContainer>
    );
}