import React, { useEffect, useState } from "react";
import { Broadcast, Character } from '../utils/type.ts';
import LoadingPage from "../LoadingPage.tsx";
import { NavbarBackBtn } from "../NavbarContainer.tsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../context/UserContext.tsx";
import toast from "react-hot-toast";
import { fetchCharacterAPI } from "../utils/api.ts";
import BroadcastBrowse from "../broadcast/BroadcastBrowse.tsx";
import Cookies from 'js-cookie';
import { model_table } from '../chat/modelList.ts'
import Markdown from "../common/markdown.tsx";
import ContentBrowse from "../content/ContentBrowse.tsx";
import { replaceSensitiveWords } from "../utils/censor/censor.ts";
import ImageWithSkeleton from "../common/ImageWithSkeleton.tsx";
import NotFoundPage from "../NotFoundPage.tsx";

interface CharacterProps {
    character: Character;
}

function GenderIcon({gender}) {
    if (gender === "男") return <i className="fa-solid fa-mars text-blue-300"></i>
    if (gender === "女") return <i className="fa-solid fa-venus  text-pink-300"></i>
}

function CharacterInfo({character}: CharacterProps) {
    return (
        <div className="flex flex-col space-y-2">
            <div className="card p-2">
                <Markdown>{replaceSensitiveWords(character.bio)}</Markdown>
            </div>
            <Link to={`/character/comment/${character.id}`} className="text-xs p-2 rounded flex flex-row items-center">
                {`评论区(${character.comments})`}
                <i className="fa-solid fa-chevron-right" />
            </Link>
        </div>
    )
}

const AllCharacterTopics = ({character}: CharacterProps) => {
    const [topics, setTopics] = useState<Broadcast[] | null>(null)

    const queryTopics = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/broadcast/topics?character_id=${encodeURIComponent(character.id)}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });
            const data = await res.json();
            setTopics(data["topics"])
        } catch (error) {
            toast.error("请稍后再试")
            return
        }
    }

    useEffect(()=>{
        queryTopics()
    }, [character])

    return (
        <div className="flex flex-col">
            {topics?.length === 0 && <p className="p-4 self-center font-bold">没有更多了</p>}
            {topics && <div className="w-full flex flex-wrap"><BroadcastBrowse broadcasts={topics} /></div>}
        </div>
    )
}

const CharacterContents = ({character}: CharacterProps) => {

    return (
        <div className="flex flex-col">
            <ContentBrowse user_id={undefined} character_id={character.id} prefix={"character-page-content-browse"+character.id} />
        </div>
    )
}

const tabs = [
    { name: '分享' },
    { name: '话题' },
];

const CharacterPageTabID = "character-page-active-tab"

export default function CharacterPage() {

    const {charID} = useParams()
    const {user} = useUser()
    const navigate = useNavigate()

    const characterLSID = `character-${charID}`
    const [character, setCharacter] = useState<Character | null>(() => {
        const localChar = localStorage.getItem(characterLSID);
        return localChar !== null ? JSON.parse(localChar) : null;
    });

    const fetchCharacterAsync = async ()=>{
        if (charID) {
            const character = await fetchCharacterAPI(charID)
            setCharacter(character)
        }
    }

    useEffect(()=>{
        fetchCharacterAsync()
    }, [])

    useEffect(() => {
        localStorage.setItem(characterLSID, JSON.stringify(character));
    }, [character]);
    
    const [activeTab, setActiveTab] = useState(() => {
        const localTab = sessionStorage.getItem(CharacterPageTabID);
        return localTab !== null ? localTab : tabs[0].name;
    });
    useEffect(() => {
        sessionStorage.setItem(CharacterPageTabID, activeTab);
    }, [activeTab]);   

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    if (character === null) {
        return <LoadingPage />
    }

    if (character && !character.id) {
        return <NotFoundPage />
    }

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(window.location.href)
        toast.success("链接已复制，立刻分享吧😊")
    };

    const navEnd = () => (
        <>
            {character.author_user_id === user?.id && <button className="btn btn-circle btn-primary btn-sm m-2" onClick={()=>navigate(`/character/create/${charID}`)}>
                <i className="far fa-edit"></i>
            </button>}
            {character.author_user_id !== user?.id && <button className="btn btn-circle btn-primary btn-sm m-2" onClick={()=>navigate(`/character/report/${charID}`)}>
                <i className="fa-solid fa-exclamation"></i>
            </button>}
        </>
    )

    const navbar = () => {
        return (
            <div className="navbar absolute left-0 top-0 w-full z-[99]">
                <NavbarBackBtn />
                <div className="navbar-start">
                </div>
                <div className="navbar-center">
                </div>
                <div className="navbar-end">
                    {navEnd()}
                </div>
            </div>
        )
    }

    const tablist = () => (
        <div role="tablist" className="tabs tabs-bordered gap-4 w-full max-w-md self-start mt-4">
            {tabs.map((tab) => (
                <a
                    key={tab.name}
                    role="tab"
                    className={`tab ${activeTab === tab.name ? 'tab-active' : ''}`}
                    onClick={() => handleTabClick(tab.name)}
                >
                    {tab.name}
                </a>
            ))}
        </div>
    )

    return (
        <div className="h-full min-h-screen overscroll-none bg-neutral">
            <div className="absolute inset-0 h-[200px]">
                <ImageWithSkeleton className="w-full h-full object-cover object-[50%_20%]" src={character.portrait_url} alt={`Avatar of ${character.name}`} />
                <div className="absolute inset-0 bg-gradient-to-t from-neutral via-transparent to-transparent bg-gradient-[rgba(var(--color-neutral),0.7),rgba(var(--color-neutral),0.35)_50%,rgba(var(--color-neutral),0)_100%]"></div>
            </div>
            {navbar()}
            <div className="flex flex-col px-3 pt-[130px]">
                <div className="card card-side px-3">
                    {character && <figure><img src={character?.avatar_url} alt={`avatar for character ${character.name}`} className="h-16 w-16 rounded-full" /></figure>}
                    <div className="card-body">
                        <div className="flex flex-row gap-1 items-center">
                            <h2 className="card-title">{character?.name}</h2>
                            <GenderIcon gender={character.gender}/>
                            {
                                model_table[character.llm_model] && <div className="btn btn-xs" >
                                    {model_table[character.llm_model].name}
                                </div>
                            }
                        </div>
                        <Link to={`/profile/${character.author_user_id}`}>
                            <div className="flex flex-row items-center gap-2 text-secondary">
                                @{character.author_nickname}
                            </div>
                        </Link>
                    </div>
                </div>
                <CharacterInfo character={character}/>
                {tablist()}
                { activeTab == tabs[0].name && <CharacterContents character={character}/> }
                { activeTab == tabs[1].name && <AllCharacterTopics character={character}/> }
            </div>
            <Link
                className="fixed bottom-20 right-4 mb-4 mr-4 font-bold py-2 px-4 btn btn-accent rounded-full"
                to={`/c/${charID}`}
            >
                <i className="fa-regular fa-comment-dots"></i>
            </Link>
        </div>
    )
}