import React, { useEffect, useState } from 'react';
import CharacterBrowse from './CharacterBrowse.tsx';
import { Link } from 'react-router-dom';

const newsItems = [
    {
        img: "/rule.webp",
        title: "次元通讯社区规则",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/Bk9wwCpCAiRBrJkrBIFcOC5Vn9b",
    },
    {
        img: "/events/group_chat.webp",
        title: "群聊功能现已上线",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/JQLpwgXuQib9KokM6dDcWHsJnIf",
    },
    {
        img: "https://rr-cn-resource-bucket.s3.cn-northwest-1.amazonaws.com.cn/system/coin_system.webp",
        title: "金币系统现已上线，点击查看规则",
        url: "https://bwbbsvqza3t.feishu.cn/wiki/MfHYwShpgirOXdkMcPjcp4HRnOg",
    },
]

function Carousel() {

    return (
        <div className="aspect-video w-full md:w-1/3 p-2">
            <div className="carousel rounded-xl">
                {
                    newsItems.map((item, index) => (
                        <div key={index} id={`carousel-item-${index}`} className={`carousel-item w-full relative`}>
                            <Link to={item.url}>
                                <img src={item.img} />
                                {item.title && <div className="absolute bottom-0 w-full text-white text-center p-4 bg-gradient-to-t from-black to-transparent">
                                    {item.title}
                                </div>}
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const GENDER_KEY = 'CharacterListGenderFilter';
const SORTING_KEY = 'CharacterListSortingFilter';

const genderOptions = ['全部', '男', '女', '其他'];
const sortingOptions = ['推荐', '最新'];

export default function CharacterListPage() {
    const [genderFilter, setGenderFilter] = useState(() => {
        return localStorage.getItem(GENDER_KEY) || '全部';
    });

    const [sortingFilter, setSortingFilter] = useState(() => {
        return localStorage.getItem(SORTING_KEY) || '推荐';
    });

    useEffect(() => {
        localStorage.setItem(GENDER_KEY, genderFilter);
    }, [genderFilter]);

    useEffect(() => {
        localStorage.setItem(SORTING_KEY, sortingFilter);
    }, [sortingFilter]);

    const handleGenderChange = (event) => {
        setGenderFilter(event.target.value);
    };

    const handleSortingChange = (event) => {
        setSortingFilter(event.target.value);
    };

    return (
        <div className="h-full w-full pt-[60px] pb-32">
            <div className="flex flex-col items-center h-full">
                <div className="flex flex-col w-full space-y-2 p-4">
                    {/* Carousel */}

                    {/* Filters */}
                    <div className="flex justify-center items-center space-x-4">
                        {/* Gender Filter */}
                        <div className="flex items-center space-x-2">
                            <label htmlFor="gender-filter">性别:</label>
                            <select
                                id="gender-filter"
                                value={genderFilter}
                                onChange={handleGenderChange}
                                className="select select-bordered select-sm"
                            >
                                {genderOptions.map((gender) => (
                                    <option key={gender} value={gender}>
                                        {gender}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Sorting Filter */}
                        <div className="flex items-center space-x-2">
                            <label htmlFor="sorting-filter">排序:</label>
                            <select
                                id="sorting-filter"
                                value={sortingFilter}
                                onChange={handleSortingChange}
                                className="select select-bordered select-sm"
                            >
                                {sortingOptions.map((sorting) => (
                                    <option key={sorting} value={sorting}>
                                        {sorting}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Character List Display */}
                    <div className="flex flex-wrap mt-4">
                        <CharacterBrowse
                            key={`${sortingFilter}-${genderFilter}`}
                            sorting={sortingFilter === '最新' ? 'latest' : 'combined'}
                            gender={genderFilter !== '全部' ? genderFilter : undefined}
                            prefix={`character-browse-${genderFilter}-${sortingFilter}`}
                            authed={false}
                            user_id={undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
