const  groupList = [
    {
        id: 'general',
        name: '社区综合',
        iconClass: 'fa-solid fa-user-group',
        description: '社区公告、综合讨论、bug反馈等',
    },
    {
        id: 'male',
        name: '男性角色',
        iconClass: 'fa-solid fa-mars',
        description: '关于男性角色的分享和讨论',
    },
    {
        id: 'female',
        name: '女性角色',
        iconClass: 'fa-solid fa-venus',
        description: '关于女性角色的分享和讨论',
    },
    {
        id: 'other',
        name: '其他角色',
        iconClass: 'fa-solid fa-gamepad',
        description: '关于非二元角色的分享和讨论',
    },
];

export default groupList;