import React, { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Markdown from "./markdown.tsx";
import { useUser } from "../context/UserContext.tsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize'
import { dateToText } from "../utils/date.js";
import { useAnimatedVirtualKeyboard } from "../utils/virtualkeyboard.ts";
import { motion } from "framer-motion";
import UserAvatar from "./UserAvatar.tsx";

function CommentForm({ commentsEndpoint, comment, can_delete, fetchComments, setParentComment }) {

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const [deleting, setDeleting] = useState(false)

    const handleDeletion = async (event) => {
        event.preventDefault();

        if (deleting) {
            return
        }
        setDeleting(true)

        const res = await fetch(`${process.env.REACT_APP_API_URL}/${commentsEndpoint}/comments/delete/${comment.id}`, {
            method: 'DELETE',
            headers: {
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
        });
        const data = await res.json();

        if (res.ok) {
            toast.success("删除成功");
        } else {
            toast.error("删除失败");
        }

        setDeleteModalOpen(false);
        fetchComments()
        setDeleting(false)
    };

    return (
        <div className="flex flex-row gap-4 py-1">
            <dialog open={isDeleteModalOpen} className="modal">
                <div className="modal-box space-y-4">
                    <h3 className="font-bold text-lg">确认删除评论吗？</h3>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn btn-error" onClick={handleDeletion} >删除</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
            <button className="inline-flex items-center text-sm font-medium text-center" onClick={() => setParentComment(comment)}>
                <i className="fa fa-regular fa-comment text-slate-400"></i>
            </button>
            {can_delete && <button className="inline-flex items-center text-sm font-medium text-center" onClick={() => setDeleteModalOpen(true)}>
                <i className="fa-regular fa-trash-can text-slate-400"></i>
            </button>}
        </div>
    );
}

export default function CommentSection({ header, commentsEndpoint }) {

    const { user } = useUser()

    const [comments, setComments] = useState<any[]>([])

    const navigate = useNavigate()
    const [reply, setReply] = useState('');
    const [parentComment, setParentComment] = useState<any>(null);
    const [submitting, setSubmitting] = useState(false)
    const { keyboardHeight, isKeyboardOpen } = useAnimatedVirtualKeyboard();

    useEffect(() => {
        fetchComments()
    }, [])

    useEffect(() => {
        setReply("")
    }, [parentComment])

    const fetchComments = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/${commentsEndpoint}/comments`, {
            method: 'GET',
        });
        const data = await res.json();
        console.log(data)

        setComments(data)
    }

    const parseNickname = (comment) => {
        return comment.author_nickname
    }

    const ReplyComment = ({ comment, parent_author_nickname,  commentsEndpoint, can_delete, fetchComments, setParentComment}) => (
        <div className="flex flex-col space-y-1">
            <div className="flex flex-row space-x-1 truncate">
                <p className="text-secondary">{parseNickname(comment)}</p>
                <p>回复</p>
                <p className="text-secondary">@ {parent_author_nickname}</p>
                <p className="text-gray-600">{dateToText(new Date(comment.time_created + "Z"))}</p>
            </div>
            <Markdown>{comment.content}</Markdown>
            <CommentForm commentsEndpoint={commentsEndpoint} comment={comment} can_delete={can_delete} fetchComments={fetchComments} setParentComment={setParentComment} />
            {comment.children && comment.children.map((reply_comment, index) => (
                <ReplyComment key={index} comment={reply_comment} parent_author_nickname={parseNickname(comment)} commentsEndpoint={commentsEndpoint} can_delete={user?.id === reply_comment.user_id} fetchComments={fetchComments} setParentComment={setParentComment} />
            ))}
        </div>
    )

    const handleSubmit = async () => {

        if (submitting) {
            return
        }
        setSubmitting(true)

        let url = `${process.env.REACT_APP_API_URL}/${commentsEndpoint}/comments/add`;
        if (parentComment && parentComment.id > 0) {
            url += `?parent_comment_id=${parentComment.id}`;
        }

        const res = await fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content: reply }),
        });
        const data = await res.json();

        if (res.ok) {
            toast.success("发布成功");
            fetchComments()
        } else {
            if (res.status === 401) {
                navigate("/login")
            } else if (res.status === 403) {
                toast("当前账号禁言中，请等待解封");
            } else {
                toast.error("发布失败");
            }
            setSubmitting(false)
            return
        }
        setReply('');
        setSubmitting(false)
    };

    return (
        <motion.div className="w-full" style={{paddingBottom: keyboardHeight}}>
            <section className="antialiased rounded-xl p-4 pb-32">
                {comments.map((comment: any, index) => (
                    <div key={index}>
                        <article className="text-base rounded-lg flex flex-col gap-2" onClick={() => setParentComment(comment)}>
                            <div className="flex flex-row justify-between items-center space-x-1 truncate">
                                <Link className="flex flex-row gap-3 text-secondary items-center" to={`/profile/${comment.user_id}`}>
                                    <UserAvatar user={{"username": comment.author_username, "avatar": comment.author_avatar}} size={10} />
                                    {parseNickname(comment)}
                                </Link>
                                <p className="text-slate-400 text-sm">{dateToText(new Date(comment.time_created + "Z"))}</p>
                            </div>
                            <Markdown>{comment.content}</Markdown>
                            <CommentForm commentsEndpoint={commentsEndpoint} comment={comment} can_delete={user?.id == comment.user_id} fetchComments={fetchComments} setParentComment={setParentComment} />
                        </article>
                        <article className="mb-3 ml-6 lg:ml-12 text-base">
                            {comment.children && comment.children.map((child_comment: any, index) => (
                                <div className="flex flex-col space-y-1">
                                    <div className="flex flex-row space-x-1 truncate items-center">
                                        <Link className="flex flex-row gap-2 items-center text-secondary" to={`/profile/${comment.user_id}`}>
                                            {parseNickname(child_comment)}
                                        </Link>
                                    </div>
                                    <Markdown>{child_comment.content}</Markdown>
                                    <CommentForm commentsEndpoint={commentsEndpoint} comment={child_comment} can_delete={user?.id == child_comment.user_id} fetchComments={fetchComments} setParentComment={setParentComment} />
                                    {child_comment.children && child_comment.children.map((reply_comment, index) => (
                                        <ReplyComment key={index} comment={reply_comment} parent_author_nickname={parseNickname(child_comment)} commentsEndpoint={commentsEndpoint} can_delete={user?.id === reply_comment.user_id} fetchComments={fetchComments} setParentComment={setParentComment} />
                                    ))}
                                </div>
                            ))}
                        </article>
                    </div>
                ))}
            </section>
            <motion.div className={`fixed w-full md:w-1/2 z-[999] bg-base-100 flex-row flex self-center justify-center items-center p-4 space-x-4 border-slate-500 border-t`} style={{bottom: keyboardHeight}}>
                <TextareaAutosize placeholder={parentComment ? `回复 @${parseNickname(parentComment)}` : "输入评论"} className="textarea textarea-bordered resize-none grow"
                    maxRows={5}
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                />
                <button className="btn rounded-full" onClick={handleSubmit} disabled={submitting}>
                    <i className="fa-regular fa-paper-plane"></i>
                </button>
            </motion.div>
        </motion.div>
    )
}