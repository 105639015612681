import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function ResetPasswordPage() {
  const [status, setStatus] = useState('idle'); // 状态：idle, loading, success, error
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidationFailed, setPasswordValidationFailed] = useState(false); // 密码验证失败标志
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();

  // 验证密码是否符合规则
  function validatePassword(password) {
    const hasValidLength = /^[a-zA-Z0-9]{8,16}$/.test(password);
    const hasBothEnglishAndNumber = /[a-zA-Z]/.test(password) && /[0-9]/.test(password);
    return hasValidLength && hasBothEnglishAndNumber;
  }

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    const validated = validatePassword(password);
    setPasswordValidationFailed(!validated);
  };

  useEffect(() => {
    if (newPassword) {
      const validated = validatePassword(newPassword);
      setPasswordValidationFailed(!validated);
    }
  }, [newPassword]);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // 表单验证
    if (!validatePassword(newPassword)) {
      setErrorMessage('密码格式不正确，8-16个字符，同时包含英文字母和数字');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage('新密码和确认密码不一致');
      return;
    }

    const token = searchParams.get('token');
    if (!token) {
      setStatus('error');
      setErrorMessage('过期或无效的链接');
      return;
    }

    setStatus('loading');
    setErrorMessage('');

    try {
      // Replace with your actual API endpoint
      const res = await fetch(`${process.env.REACT_APP_API_URL}/user/password/reset?password=${newPassword}&token=${token}`);

      if (res.status === 200) {
        setStatus('success');
      } else {
        setStatus('error');
        const errorData = await res.json();
        setErrorMessage(errorData.detail || '修改密码失败');
      }
    } catch (error) {
      console.error(error);
      setStatus('error');
      setErrorMessage('网络错误，请稍后重试');
    }
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen p-8">
      <div className="w-full max-w-lg text-center card">

        {status === 'idle' && (
          <form onSubmit={handleChangePassword} className="space-y-4">
            <div>
              <label className="label">
                <span className="text-base label-text">新密码</span>
              </label>
              <input
                type="password"
                placeholder="8-16个字符，同时包含英文字母和数字"
                className="w-full p-2 border rounded-md"
                onChange={handlePasswordChange}
              />
              <label
                className="label"
                style={{ display: passwordValidationFailed ? 'block' : 'none' }}
              >
                <span className="text-base label-text" style={{ color: 'red' }}>
                  8-16个字符，同时包含英文字母和数字
                </span>
              </label>
            </div>

            <div>
              <input
                type="password"
                className="w-full p-2 border rounded-md"
                placeholder="确认新密码"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>

            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <button
              type="submit"
              className="w-full p-2 mt-4 font-bold  bg-primary rounded-md"
              disabled={passwordValidationFailed}
            >
              修改密码
            </button>
          </form>
        )}

        {status === 'loading' && (
          <div className="flex items-center justify-center space-x-2 animate-bounce">
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
          </div>
        )}

        {status === 'success' && (
          <div className="text-center">
            <h2 className="mt-4 text-2xl font-bold text-green-300">密码修改成功</h2>
            <p className="mt-2 text-slate-400">你现在可以使用新密码登录了</p>
          </div>
        )}

        {status === 'error' && (
          <div className="text-center">
            <h2 className="mt-4 text-2xl font-bold text-red-300">密码修改失败</h2>
            <p className="mt-2 text-slate-400">
              {errorMessage || '出现错误，请稍后再试'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
