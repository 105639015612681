import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast"
import Cookies from 'js-cookie';
import {Howl} from 'howler';
import { agentChat, cookCharacterBroadcast, deleteCharacterEvent, editCharacterEvent, fetchCharacterAPI } from "../utils/api.ts";
import { Character } from "../utils/type.ts";

function useVoiceBot(charID: string) {

  const historyLSID = `history-${charID}`
  const [history, setHistory] = useState<Array<Object> | null>(() => {
    const localHistory = localStorage.getItem(historyLSID);
    return localHistory !== null ? JSON.parse(localHistory) : null;
  });
  useEffect(() => {
    if (history?.constructor === Array) {
      try {
        localStorage.setItem(historyLSID, JSON.stringify(history));
      } catch {
        toast.error("本地容量已满，请清理聊天记录")
      }
    }
  }, [history]);

  const characterLSID = `character-${charID}`
  const [character, setCharacter] = useState<Character | null>(() => {
    const localChar = localStorage.getItem(characterLSID);
    return localChar !== null ? JSON.parse(localChar) : null;
  });
  useEffect(() => {
    localStorage.setItem(characterLSID, JSON.stringify(character));
  }, [character]);

  const [processing, setProcessing] = useState(false)
  const [needFund, setNeedFund] = useState(false)

  const [voiceOn, setVoiceOn] = useState(() => {
    const savedVoiceOn = localStorage.getItem('voiceOn');
    return savedVoiceOn !== null ? JSON.parse(savedVoiceOn) : false;
  });

  const moodOn = false

  useEffect(() => {
    localStorage.setItem('voiceOn', JSON.stringify(voiceOn));
  }, [voiceOn]);

  // make stateRef always have the current count
  // your "fixed" callbacks can refer to this object whenever
  // they need the current value.  Note: the callbacks will not
  // be reactive - they will not re-run the instant state changes,
  // but they *will* see the current value whenever they do run
  const stateRef = useRef();
  stateRef["processing"] = processing;

  const initializeHistory = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/history?limit=100`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
      });

      const data = await res.json();
      if (data.constructor === Array) {
        setHistory(data)
      }
      // connectWS() disable websocket for now
    } catch (e) {
      console.error(e);
    }
  };

  // Run once on initialization
  useEffect(() => {
    const initializeCharacter= async () => {
      const character = await fetchCharacterAPI(charID)
      setCharacter(character)
    }
    initializeCharacter();
    initializeHistory();
  }, []);

  const sentenceStop = "。！？.!?\n";
  
  const getFirstSentence = (text) => {
    const sentenceRegex = new RegExp("[^" + sentenceStop + "]+[" + sentenceStop + "]?");
    const match = sentenceRegex.exec(text.split("\n")[0]);
    return match ? match[0].trim() : '';
  } 

  const playAudio = async (text) => {
    text = getFirstSentence(text)
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/tts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Cookies.get('ringriseusertoken')}`,
        },
        body: JSON.stringify({
          text: text
        })
      });

      const audioBlob = await res.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      if (audioUrl) {
        var sound = new Howl({
          src: [audioUrl],
          format: ['mp3']
        });
        sound.play();
        URL.revokeObjectURL(audioUrl);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const userText = (text: string, model: number) => {
    if (processing) {
      return
    }

    if (text) {
      const newMessage = {
        "role": "user",
        "content": text,
        "date": Date.now(),
        "type": "chat",
      }
      setHistory(prev => [...prev || [], newMessage]);
      try {
        send([newMessage], model)
      } catch (e) {
        toast.error("暂时无法发送🙅")
        setProcessing(false)
        return
      }
    }
  }

  const getUpdateMessages= (messages: any[], newContent: string, eventID: number) => {
    // Copy the messages array to avoid direct state mutation
    const updatedMessages = [...messages];

    // Check if the last message exists and if its eventId is unset (assuming "unset" means either null, undefined, or an empty string)
    if (updatedMessages.length > 0 && !updatedMessages[updatedMessages.length - 1].id) {
      // Update the eventId of the last message
      updatedMessages[updatedMessages.length - 1].id = eventID;
    }

    // Append the new message with the current eventId
    updatedMessages.push({
      id: eventID,
      type: "chat",
      role: "assistant",
      content: newContent,
      date: Date.now(),
      mood: 0,
    });

    return updatedMessages
  }


  const send = async (messages: any[], model: number) => {
    console.log("start send")
    setProcessing(true)

    const result = await agentChat(messages, charID, model, moodOn, ()=>{})
    if (result.status === 500) {
      setNeedFund(true)
      setProcessing(false)
      return
    }
    if (!result.body) {
      setProcessing(false)
      return
    }
    console.log(result)

    // refresh cookie expire date
    const token = Cookies.get('ringriseusertoken')
    Cookies.set('ringriseusertoken', token, { expires: 7 })

    const content = result.body.reply
    const eventId = result.body.event_id
    // if (voiceOn) {
    //  await playAudio(content)
    // }
    setHistory(prev => getUpdateMessages(prev || [], content, eventId));
    setProcessing(false)
  }

  const clearHistory = async (charID) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}/history/bulkdelete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
        }
      })
      await initializeHistory()
    } catch (e) {
      console.log(e)
      toast.error("Failed")
      return
    }
  }

  const cookAIText = async (character_id: string, topic_id: number, rewrite: boolean) => {
    setProcessing(true)
    if (rewrite) {
      toast("生成中，请稍等")
    }
    const result = await cookCharacterBroadcast(character_id, topic_id)
    if (result) {
      console.log(result)
      var tmpHistory = [...(history || [])]
      for (let message of result["response"]) {
        tmpHistory.push({
          "id": result["event_id"],
          "type": "broadcast",
          "role": message["role"],
          "content": message["content"],
          "date": Date.now(),
          "hide": message["hide"],
          "broadcast_id": result["broadcast_id"],
        })
        setHistory(tmpHistory);
      }
    } else {
      toast.error("生成失败，请稍后再试");
      setProcessing(false)
      return false
    }
    setProcessing(false)
    return true
  }

  const deleteEvent = async (event_id: number) => {
    if (!history || processing) return

    setProcessing(true)
    setHistory(prev => (prev || []).filter(item => item["id"] !== event_id))
    const _ = await deleteCharacterEvent(event_id)
    setProcessing(false)
  }

  const regenEvent = async (character_id: string, event_id: number, model: number) => {
    if (!history || processing) return

    const first = history.find(item => item["id"] == event_id)
    if (!first) return

    // normal case, delete and resend
    if (first["role"] == "user" && first["type"] == "chat") {
      const text = first["content"]
      const _ = await deleteEvent(event_id)
      userText(text, model)
    }

    // broadcast, recook
    if (first["type"] == "broadcast" && first["broadcast_id"]) {
      const broadcast_id = first["broadcast_id"]
      const _ = await deleteEvent(event_id)
      await cookAIText(character_id, broadcast_id, true)
    }
  }

  const editEvent = async (event_id: number, content: string) => {
    if (!history || processing) return
    const success = await editCharacterEvent(event_id, content)
    if (!success) {
      toast.error("修改失败，请稍后再试")
      return
    }
    setHistory(prev => {
      const newHistory = [...prev || []]
      newHistory[newHistory.length - 1] = {
        ...newHistory[newHistory.length - 1],
        "content": content,
        "date": Date.now(),
        "mood": 0,
      }
      return newHistory
    });
  }

  return {
    processing,
    character,
    history,
    userText,
    cookAIText,
    deleteEvent,
    regenEvent,
    editEvent,
    clearHistory,
    voiceOn,
    setVoiceOn,
    needFund,
  };
}


export default useVoiceBot;
