import React, { useState, useEffect } from 'react';

export default function ForgetPasswordPage() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(''); // 'loading', 'success', 'error'
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (validateEmail(email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setIsEmailValid(false);
      return;
    }

    setStatus('loading');
    setErrorMessage('');

    try {
      // Replace with your actual API endpoint
      const res = await fetch(`${process.env.REACT_APP_API_URL}/user/password/forget?email=${email}`);

      if (res.status === 200) {
        setStatus('success');
      } else {
        setStatus('error');
        const errorData = await res.json();
        setErrorMessage(errorData.detail || '出现错误，请稍后再试');
      }
    } catch (error) {
      console.error(error);
      setStatus('error');
      setErrorMessage('网络错误，请稍后重试');
    }
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen p-8">
      <div className="w-full max-w-lg text-center card">
        <h2 className="text-2xl font-bold">忘记密码</h2>
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <div>
            <input
              type="email"
              placeholder="请输入邮箱"
              className={`p-2 border rounded w-full ${isEmailValid ? 'border-green-500' : 'border-red-500'}`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {!isEmailValid && email && (
              <p className="mt-1 text-sm text-red-500">请输入有效的邮箱地址</p>
            )}
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-primary w-full"
            disabled={!isEmailValid}
          >
            发送邮件
          </button>
        </form>

        {status === 'loading' && (
          <div className="flex items-center justify-center mt-4 space-x-2 animate-bounce">
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
            <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
          </div>
        )}

        {status === 'success' && (
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-green-300">请求已发送</h2>
            <p className="mt-2 text-slate-400">请检查你的邮箱以重设密码</p>
          </div>
        )}

        {status === 'error' && (
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-red-300">请求失败</h2>
            <p className="mt-2 text-slate-400">
              {errorMessage || '邮箱无效或请求出错，请重试'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
