import React from 'react';
import groupList from '../utils/groupList.ts';
import { Link } from 'react-router-dom';

export default function ContentGroupListPage() {
    
    return (
        <div className="h-full flex flex-col items-center">
            <div className="pb-32 pt-16 w-full">
                <div className="flex flex-col items-center h-full w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex flex-col w-full">
                            {groupList.map((group, index) => (
                                <Link to={`/content?group=${group.id}`}>
                                    <div
                                        key={index}
                                        className="bg-base-100 w-full flex items-start p-2 my-2"
                                    >
                                        <div className="w-16 h-16 mr-4 flex items-center justify-center rounded-full">
                                            <i className={`${group.iconClass} fa-2x`}></i>
                                        </div>
                                        <div>
                                            <h2 className="text-lg font-semibold">{group.name}</h2>
                                            <p className="text-slate-400">{group.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
