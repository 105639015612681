import React from "react";
import { Link } from "react-router-dom";
import Markdown from "../common/markdown.tsx";

export default function CharacterEntry({ character }) {
    return (
        <Link to={`/character/${character.id}`} className="bg-base-100 flex flex-row p-2 border-b-[1px] border-slate-200">
            <img className="w-16 h-16 rounded-full" src={character.avatar_url} alt={`Avatar of ${character.name}`} />
            <div className="flex flex-col px-2">
                <div className="line-clamp-1 text-lg">
                    <b>{character.name}</b> @{character.author_nickname}
                </div>
                <div className="line-clamp-1 text-slate-400">
                    <Markdown>{`${character.bio}`}</Markdown>
                </div>
                <div className="line-clamp-1 text-slate-400">
                    {character.public ? `${character.count}条对话`: "私密"}
                </div>
            </div>
        </Link>
    )
}