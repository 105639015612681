import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { writerUpdateCharacterAPI } from '../utils/api.ts';
import Zoom from 'react-medium-image-zoom';

interface Report {
  id: number;
  character_id: string;
  category: string;
  remark: string;
  image_url: string;
  created_at: string;
  character: {
    name: string;
    bio: string;
    extra_prompt: string;
    public: boolean;
    portrait_url: string;
  };
}

const ReportsPage = () => {
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/reports`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
            },
          }
        );
        const data = await response.json();
        console.log(data)
        setReports(data.reports);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    fetchReports();
  }, []);

    const handleDeleteReport = async (report: Report) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/characters/${report.character_id}/report`, {
                method: 'DELETE',
                headers: {
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
            });

            if (response.ok) {
                setReports(reports.filter((item) => item.character_id !== report.character_id));
            } else {
                console.error('Error deleting report');
            }
        } catch (error) {
            console.error('Error deleting report:', error);
        }
    };

  const handleReport = async (report: Report) => {
    await writerUpdateCharacterAPI(report.character_id, report.character.bio, report.character.extra_prompt, false, () => { })
    handleDeleteReport(report)
  };

  return (
    <div>
      <h1>Reports</h1>
      <table>
        <thead>
          <tr>
            <th>Character Name</th>
            <th>Portrait</th>
            <th>Image</th>
            <th>Category</th>
            <th>Remark</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <tr key={report.id}>
              <td>{report.character.name}</td>
              <td>
                <Zoom>
                    <img src={report.character.portrait_url} alt="avatar" style={{ maxWidth: '100px' }} />
                </Zoom>
              </td>
              <td>
                <Zoom>
                    <img src={report.image_url} alt="evidence" style={{ maxWidth: '100px' }} />
                </Zoom>
              </td>
              <td>{report.category}</td>
              <td>{report.remark}</td>
              <td>
                <button className="btn" onClick={() => handleDeleteReport(report)}>Delete</button>
                <button className="btn" onClick={() => handleReport(report)}>Handle</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsPage;