import React, { useEffect, useRef, useState } from "react";
import { Character } from "./utils/type";
import Cookies from "js-cookie";
import CharacterEntry from "./character/CharacterEntry.tsx";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const SearchPage: React.FC = () => {
    const [query, setQuery] = useState('');
    const [characters, setCharacters] = useState<Character[]>([]);
    const [loading, setLoading] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const location = useLocation();

    useEffect(() => {
        
        const searchParams = new URLSearchParams(location.search);
        const q = searchParams.get('q');
        if (q) {
            setQuery(q);
            searchCharacters(q);
        } else {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [location.search]);

    const searchCharacters = async (q) => {
        setLoading(true);
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify({
                    "page_size": 100,
                    "q": q,
                    "mona": false,
                })
            });
            if (!res.ok) {
                throw new Error('Failed to fetch characters');
            }
            const data = await res.json();
            setCharacters(data.characters);
        } catch (err) {
            toast.error("搜索失败");
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (loading) {
            return
        }
        if (e.keyCode === 13 && !e.shiftKey && query.trim() !== '') {
            e.preventDefault()
            searchCharacters(query)
        }
    }

    const searchBar = () => (
        <label className="input input-bordered flex items-center grow gap-2 my-1">
             <input
                    ref={inputRef}
                    type="text"
                    placeholder="搜索角色..."
                    className="grow bg-base-100"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                />
            <i className="fa-solid fa-search" />
        </label>
    )

    const navigate = useNavigate()

    return (
        <div className={"flex flex-col bg-gradient-to-t h-screen from-grad-100 via-grad-200 to-grad-300"}>
        <div className="navbar fixed top-0 w-full z-[999] bg-base-100">
            <div className="flex-none">
                <button className="btn btn-ghost" onClick={()=>navigate(-1)}>
                    <i className="fa-solid fa-chevron-left"></i>
                </button>
            </div>
            <div className="me-8 w-full">
                {searchBar()}
            </div>
            </div>
        <div className="h-full overflow-y-auto overflow-x-hidden">
            <div className="pb-32 pt-[64px] ">
            <div className="mt-4 w-full h-full">
                {loading && <span className="loading loading-spinner loading-sm m-4 self-center"></span> }
                {!loading && (characters.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {characters.map((character, index) => (
                            <CharacterEntry character={character} key={index} />
                        ))}
                    </div>
                ) : (
                    <div className="text-center">无搜索结果</div>
                ))}
            </div>
            </div>
        </div>
        </div>
    );
};

export default SearchPage;