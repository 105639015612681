import React from 'react';
import ContentBrowse from './ContentBrowse.tsx';
import { Link } from 'react-router-dom';
import NavbarContainer from '../NavbarContainer.tsx';
import groupList from '../utils/groupList.ts';

export default function ContentListPage() {
    

    // Extract the "group" parameter from the query string
    const searchParams = new URLSearchParams(window.location.search);
    const group = searchParams.get('group');

    const navEnd = () => (
        <Link to={`/content/it/create?group=${group}`} className="btn btn-ghost">
            发帖
        </Link>
    )

    return (
        <NavbarContainer title={groupList.find(obj => obj.id === group)?.name} navEnd={navEnd}>
            <div className="flex flex-col items-center h-full w-full">
                <div className="flex flex-col w-full">
                    <div className="flex flex-col py-4 pt-0">
                        <ContentBrowse user_id={undefined} character_id={undefined} group={group} prefix={`content-group-${group}`}/>
                    </div>
                </div>
            </div>
        </NavbarContainer>
    )
};