import React, { useEffect, useRef, useState } from 'react';
import { createCharacterAPI, fetchCharacterAPI, generateImage, uploadResource } from '../utils/api.ts';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AvatarCropper from '../AvatarCropper.tsx';
import NavbarContainer from '../NavbarContainer.tsx';
import Zoom from 'react-medium-image-zoom'
import toast from 'react-hot-toast';
import { Character } from '../utils/type.ts';
import LoadingPage from '../LoadingPage.tsx';
import { model_table, stable_model_list, model_price_string } from '../chat/modelList.ts';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { replaceSensitiveWords } from '../utils/censor/censor.ts';
import Cookies from "js-cookie"

const questions = [
    {
        id: 'name',
        label: '昵称',
        placeHolder: '小夕',
        maxLen: '20'
    },
    {
        id: 'gender',
        label: '性别',
        options: ['男', '女', '其他'],
    },
    {
        id: 'bio',
        label: '角色简介',
        placeHolder: '角色的简单介绍（只用于向其他用户展示）',
        maxLen: '500',
        textArea: true,
    },
    {
        id: 'extra_prompt',
        label: '详细设定',
        placeHolder: '说话风格、爱好、背景故事等。',
        maxLen: '1000',
        textArea: true,
    },
    {
        id: 'greeting',
        label: '开场白',
        placeHolder: '角色聊天时说的第一句话。',
        maxLen: '1000',
        textArea: true,
    },
    /*{
        id: 'voice',
        label: '声线',
        selections: [
            {"key": "male_1", "value": "男声-清亮"},
            {"key": "male_2", "value": "男声-精英"},
            {"key": "male_3", "value": "男声-温和"},
            {"key": "female_1", "value": "女声-邻家"},
            {"key": "female_2", "value": "女声-柔婉"},
            {"key": "female_3", "value": "女声-娇俏"}
        ],
    },*/
    {
        id: 'llm_model',
        label: '默认AI模型',
        selections: stable_model_list.map(v=>({
            "key": v,
            "value": `${model_table[v].name}：${model_table[v].description} （${model_price_string(model_table[v].price)}）`
        })),
    },
]

const default_llm_model = 5;

function CharacterCreator() {
    const [choices, setChoices] = useState({
        "public": true,
        "voice": "male_1",
        "llm_model": default_llm_model,
        "inspiration_price": "0",
        "allow_mona": true,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    // avatar related
    const [showAvatarCropper, setShowAvatarCropper] = useState(false);
    const [avatar, setAvatar] = useState("")
    const [portraitSrc, setPortraitSrc] = useState("")
    const [portraitLoading, setPortraitLoading] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    // 声线试听
    const [characterVoice, setCharacterVoice] = useState({})
    const [isPlayingVoice, setIsPlayingVoice] = useState(false)

    const{ charID } = useParams()

    const [character, setCharacter] = useState<Character | null>(null);

    const isNewCharacter = charID === "0"
    useEffect(() => {
        const initializeCharacter= async () => {
            // new character creation
            if (isNewCharacter) {
                return
            }
            const character = await fetchCharacterAPI(charID || "")
            setCharacter(character)
        }
        initializeCharacter()
    }, [])

    const imageGenerateDialogRef = useRef<null | HTMLDialogElement>(null)
    const confirmCreationDialogRef = useRef<null | HTMLDialogElement>(null)
    const confirmDeletionDialogRef = useRef<null | HTMLDialogElement>(null)

    useEffect(()=>{
        if (character != null) {
            setChoices({
                "gender": character.gender,
                "appearance": character.appearance,
                "name": character.name,
                "extra_prompt": character.extra_prompt,
                "greeting": character.greeting,
                "public": character.public,
                "bio": character.bio,
                "voice": character.voice || (character.gender === "男" ? "male_1": "female_1"),
                "llm_model": character.llm_model || default_llm_model,
                "inspiration_price": character.inspiration_price,
                "allow_mona": character.allow_mona,
            })
            setAvatar(character.avatar_url)
            setPortraitSrc(character.portrait_url)
        } else {
            setChoices({
                ...choices,
                "gender": searchParams.get("gender"),
                "name": searchParams.get("name"),
                "voice": searchParams.get("voice") || "male_1",
                "extra_prompt": searchParams.get("extra_prompt"),
                "greeting": searchParams.get("greeting"),
                "bio": searchParams.get("bio")
            })
            setPortraitSrc(searchParams.get("portrait_url") || portraitSrc)
        }
    }, [character])

    const navigate = useNavigate()

    if (!isNewCharacter && !character) {
        return <LoadingPage />
    }

    const handleInpntChange = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        //event.target.name
        setChoices({
            ...choices,
            [event.target.name]: event.target.value
        });
    };

    const handleOptionSelect = (question, option: string) => {
        if (question.multiple) {
            // For multiple selection, toggle the option in an array
            setChoices({
                ...choices,
                [question.id]: choices[question.id] ?
                    choices[question.id].includes(option)
                        ? choices[question.id].filter(item => item !== option)
                        : [...choices[question.id], option]
                    : [option],
            });
        } else {
            setChoices({ ...choices, [question.id]: option });
        }
    };
    
    const onAvatarCropped = (avatar) => {
        setAvatar(avatar)
        setShowAvatarCropper(false);
    }
    
    const appearancePrompt = (appearance) => appearance + ",best quality,masterpiece"
    
    const generatePortraitImage = async() => {
      const appearance = choices["appearance"]
      if ( portraitLoading || !appearance) {
        return
      }
      setAvatar("")
      setPortraitSrc("")
      setPortraitLoading( true )
      const prompt = appearancePrompt(appearance)
      const images = await generateImage(prompt, null);
      console.log(images)
      if (images.length > 0) {
        setPortraitSrc(images[0])
        setPortraitLoading(false)
      }
      setPortraitLoading(false)
    };

    const confirmCreation = async () => {
        if ( confirmLoading ) {
            return
        }

        if (choices.public && choices["bio"] != replaceSensitiveWords(choices["bio"]) || choices["greeting"] != replaceSensitiveWords(choices["greeting"])) {
            toast("请修改违规内容（简介/开场白）")
            return
        }

        setConfirmLoading(true)

        var avatarURL = avatar
        // upload Avatar
        if (avatar.startsWith("data")) {
            let blob = await (await fetch(avatar)).blob();
            let fileName = "avatar.png";
            let file = new File([blob], fileName, { type: blob.type });
            avatarURL = await uploadResource(file, ()=> {})
            if (!avatarURL) {
                setConfirmLoading(false)
                return
            }
        }

        // upload portrait if needed
        var portraitURL = portraitSrc
        if (portraitSrc.startsWith("blob:")) {
            let blob = await (await fetch(portraitSrc)).blob();
            let fileName = "portrait";
            let file = new File([blob], fileName, { type: blob.type });
            const serverSrc = await uploadResource(file, ()=> {})
            if (!serverSrc) {
                setConfirmLoading(false)
                return
            }
            portraitURL = serverSrc
            setPortraitSrc(portraitURL)
        }
        const characterToPost = {
            id: character?.id,
            name: choices["name"],
            gender: choices["gender"],
            avatar_url: avatarURL,
            portrait_url: portraitURL,
            appearance: choices["appearance"] || "",
            appearance_prompt: appearancePrompt(choices["appearance"]),
            extra_prompt: choices["extra_prompt"],
            greeting: choices["greeting"],
            public: choices["public"],
            bio: choices["bio"],
            voice: choices["voice"],
            llm_model: choices["llm_model"],
            inspiration_price: choices["inspiration_price"],
            allow_mona: choices["allow_mona"],
        }
        console.log(characterToPost)
        const data = await createCharacterAPI(characterToPost, ()=>{navigate("/login")})
        console.log(data)
        if (!data) {
            toast.error("创建角色失败")
            setConfirmLoading(false)
            return
        }
        const id = data["id"]
        setConfirmLoading(false)

        if (id) {
            if (isNewCharacter) {
                navigate(`/c/${id}?back=${encodeURIComponent("/chat")}`)
                return
            }
            navigate(-1)
        }
    }

    const confirmDeletion = async () => {
        if (confirmLoading) {
            return
        }
        setConfirmLoading(true)
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/characters/${charID}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Cookies.get('ringriseusertoken')}`,
                },
                body: JSON.stringify(character)
            });

            if (res.status !== 200) {
                toast.error("删除角色失败🙅");
                setConfirmLoading(false)
                return null
            }
        } catch (e) {
            toast.error("删除角色失败🙅");
            setConfirmLoading(false)
            return null
        }
        setConfirmLoading(false)
        navigate("/me")
    }

    const handlePortraitImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            const url = URL.createObjectURL(img)
            console.log(url)
            setPortraitSrc(url);
        } else {
            URL.revokeObjectURL(portraitSrc)
            setPortraitSrc("")
        }
    }

    const confirmCreationDialog = () => {
        return (
            <dialog ref={confirmCreationDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    {character?.id ? <h3 className="font-bold text-lg">确定要更新这个角色吗？</h3>:  <h3 className="font-bold text-lg">确定要创建这个新角色吗？</h3>}
                    <div className="flex flex-col space-y-2" >
                        <p>更改将立刻生效。</p>
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" onClick={confirmCreation} >确定</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    function showConfirmDeletionDialog() {
        if (confirmDeletionDialogRef && confirmDeletionDialogRef.current) confirmDeletionDialogRef.current.showModal()
    }

    const confirmDeletionDialog = () => {
        return (
            <dialog ref={confirmDeletionDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    {character?.id ? <h3 className="font-bold text-lg">确定要删除这个角色吗？</h3>:  <h3 className="font-bold text-lg">确定要创建这个新角色吗？</h3>}
                    <div className="flex flex-col space-y-2" >
                        <p>操作将不可撤销。</p>
                    </div>
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn btn-error" onClick={confirmDeletion} >确定</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }
    function showConfirmCreationDialog() {
        if (confirmCreationDialogRef && confirmCreationDialogRef.current) confirmCreationDialogRef.current.showModal()
    }

    const imageGeneratorDialog = () => {
        return (
            <dialog ref={imageGenerateDialogRef} className="modal">
                <div className="modal-box space-y-2">
                    <h3 className="font-bold text-lg">角色立绘</h3>
                    <div className="flex flex-col space-y-2" >
                        <label htmlFor="portrait-upload" className="btn btn-primary mt-4">本地上传</label>
                        <input id="portrait-upload" className="file-input mt-4" type="file" onChange={handlePortraitImageUpload} hidden/>
                        <p className="text-sm text-slate-400">ℹ️ 请勿上传侵权图片</p>
                    </div>
                    {portraitSrc ? <Zoom><img src={portraitSrc} alt="portrait" className="h-48 rounded" /></Zoom>: null}
                    <div className="modal-action">
                        <form method="dialog" className="space-x-4">
                            <button className="btn" >完成</button>
                        </form>
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        )
    }

    function showImageGenerateDialog() {
        if (imageGenerateDialogRef && imageGenerateDialogRef.current) imageGenerateDialogRef.current.showModal()
    }

    const ImageGenerator = () => (
          <div className="space-y-4"> 
            {imageGeneratorDialog()}
            <div className="flex flex-row space-x-4 py-4">  
                <button className="flex justify-center items-center h-36 w-36 border-dashed border-2 border-slate-400 rounded" onClick={showImageGenerateDialog}>
                    {portraitSrc ? <img src={portraitSrc} alt="portrait" className="h-36 rounded" />: "立绘"}
                </button>
                <button className="h-36 w-36 border-dashed border-2 border-slate-400 rounded-full" disabled={!portraitSrc} onClick={()=>setShowAvatarCropper(true)}>
                    {avatar ? <img src={avatar} alt="avatar" className="h-36 rounded-full" />: "头像"}
                </button>
            </div>
            {showAvatarCropper && ( 
                <AvatarCropper 
                    src={portraitSrc}
                    setShow={setShowAvatarCropper}
                    onConfirm={onAvatarCropped}
                /> 
            )}
          </div>
    )

    const handlePublicCheck = ()=> {
        setChoices({...choices, "public": !choices["public"]})
    }
    const handleMonaCheck = ()=> {
        setChoices({...choices, "allow_mona": !choices["allow_mona"]})
    }

    const publicChecker = () => (
        <>
            <div className="flex flex-row gap-2 mt-8">
                <input type="checkbox" checked={choices["public"]} onClick={handlePublicCheck} className="checkbox checkbox-secondary" />
                <p>公开角色</p>
            </div>
            {choices["public"] && <div className="flex flex-row gap-2 mt-2">
                <input type="checkbox" checked={choices["allow_mona"]} onClick={handleMonaCheck} className="checkbox checkbox-secondary" />
                <p>同步公开角色到 <Link className="underline" to={'http://xhslink.com/a/BMEW8PRhTfRY'}>MonaChat</Link> </p>
            </div>}
        </>
    )

    const playVoice = async () => {
        if(!isPlayingVoice) {
            setIsPlayingVoice(true)
            const voice = choices['voice']

            if (voice in characterVoice) {
                characterVoice[voice].play()
            } else {
                try {
                    const res = await fetch(`https://rr-cn-resource-bucket.s3.cn-northwest-1.amazonaws.com.cn/system/${voice}.mp3`)
                    const audioBlob = await res.blob()

                    const audioUrl = URL.createObjectURL(audioBlob)
                    const audio = new Audio(audioUrl)
                    audio.onended = () => {
                        setIsPlayingVoice(false)
                    }
                    audio.play()
                    setCharacterVoice({ ...characterVoice, [voice]: audio })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }

    const questionEntry = (question) => {
        if (question.options) {
            return (
                <div className="flex flex-row flex-wrap">
                    {question.options.map((option) => (
                        <div className="p-1">
                            <button
                                key={option}
                                className={`btn btn-outline btn-md ${choices[question.id]?.includes(option) ? 'btn-secondary' : 'btn-success'}`}
                                onClick={() => handleOptionSelect(question, option)}
                            >
                                {option}
                            </button>
                        </div>
                    ))}
                </div>
            )
        }
        if (question.selections) {
            return (
                <div className="flex flex-row flex-wrap space-x-2">
                    <select className="select"
                        value={choices[question.id]}
                        onChange={(e) => handleOptionSelect(question, e.target.value)}>
                        {question.selections.map((item) => (
                            <option key={item.key} value={item.key}>{item.value}</option>
                        ))}
                    </select>
                    {question.id == "voice" && <i className={`fa fa-volume-high py-3 ${isPlayingVoice ? 'text-gray-400' : ''}`} onClick={playVoice}></i>}
                </div>
            )
        }
        if (question.textArea) {
            return (
                <>
                    <ReactTextareaAutosize minRows={4} name={question.id} placeholder={question.placeHolder} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="textarea textarea-bordered w-full max-w-md"/>
                    {question.maxLen && <div className="text-sm text-slate-400">({choices[question.id]?choices[question.id].length: 0}/{question.maxLen})</div>}
                </>
            )
        }
        return (
            <input type="text" name={question.id} placeholder={question.placeHolder} maxLength={question.maxLen} value={choices[question.id]} onChange={handleInpntChange} className="input input-bordered w-full max-w-md" />
        )
    }

    const valid = () => {
        for (const question of questions) {
            if (!choices[question.id]) {
                return false
            }
        }
        return portraitSrc && avatar
    }

    const userInspiration = () => (
        <div className="flex flex-col gap-2 my-4 p-2 bg-base-100 w-full max-w-md rounded-lg">
                <div className="flex flex-row space-x-2 items-center">
                    <div className="label">作者定价:</div>
                    <i className="fa-solid fa-coins"></i>
                    <div>{choices['inspiration_price']}  / 消息</div>
                </div>
                <input className="range" type="range" step="0.1" max="3" min="0" name='inspiration_price' value={choices['inspiration_price']} onChange={handleInpntChange} ></input>
                <p className="text-sm text-slate-400"># 其他用户使用角色时会向你额外支付的价格</p>
        </div>
    )

    const navEnd = () => (
        <button className="btn btn-ghost" onClick={showConfirmCreationDialog}  disabled={!valid() || confirmLoading}>{ confirmLoading && <span className="loading loading-spinner"></span>}保存</button>
    )
    
    return (
        <NavbarContainer title={isNewCharacter?"创建角色":"编辑角色"} navEnd={navEnd}>
            <div className="p-5">
                {ImageGenerator()}
                {questions.map((question) => (
                    <div className="flex flex-col space-y-4">
                        <div className="flex flex-col">
                            <div className="label">{question.label}</div>
                            {questionEntry(question)}
                        </div>
                    </div>
                ))}
                {publicChecker()}
                {choices["public"] && userInspiration()}
                {charID !== "0" && <button className="btn btn-error mt-10" onClick={showConfirmDeletionDialog}>删除角色</button>}
                {confirmCreationDialog()}
                {confirmDeletionDialog()}
            </div>
        </NavbarContainer>
    );
}

export default CharacterCreator;
